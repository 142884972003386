<template>
  <div class="relative text-center p-8 rounded-xl">
    <div 
      class="absolute inset-0 rounded-xl"
      :style="{
        backgroundColor: styleStore.backgroundColor,
        opacity: styleStore.backgroundOpacity
      }"
    ></div>
    
    <div class="relative space-y-4">
      <h1 
        :class="[
          'text-2xl',
          { 'dark:text-white': !styleStore.textColor }
        ]"
        :style="{
          fontFamily: styleStore.fontFamily,
          fontSize: styleStore.fontSize,
          fontWeight: styleStore.fontWeight,
          color: styleStore.textColor || undefined,
          opacity: styleStore.textOpacity
        }"
      >
        {{ store.isFocusTime ? $t('focus_time') : $t('break_time') }}
      </h1>
      
      <div 
        :class="[
          'text-6xl',
          { 'dark:text-white': !styleStore.textColor }
        ]"
        :style="{
          fontFamily: styleStore.fontFamily,
          fontSize: styleStore.fontSize,
          fontWeight: styleStore.fontWeight,
          color: styleStore.textColor || undefined,
          opacity: styleStore.textOpacity
        }"
      >
        {{ store.isEnded ? $t('session_completed') : formatTime(store.timeLeft) }}
      </div>
      
      <div 
        :class="[
          'text-gray-600',
          { 'dark:text-white': !styleStore.textColor }
        ]"
        :style="{
          fontFamily: styleStore.fontFamily,
          fontSize: `${parseInt(styleStore.fontSize) * 0.5}pt`,
          fontWeight: styleStore.fontWeight,
          color: styleStore.textColor || undefined,
          opacity: styleStore.textOpacity
        }"
      >
        {{ store.isEnded ? '' : $t('cycle_count', { current: store.currentCycle, total: store.cycles }) }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTimerStore } from '../stores/timer'
import { useStyleStore } from '../stores/style'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const store = useTimerStore()
const styleStore = useStyleStore()
const { t } = useI18n()

const isDarkMode = computed(() => {
  return document.documentElement.classList.contains('dark')
})

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`
}
</script>
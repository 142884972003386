<!-- App.vue -->
<template>
    <div class="min-h-screen bg-white dark:bg-gray-900 transition-colors">
      <div class="container mx-auto px-4 py-2">
        <div class="max-w-md mx-auto">
          <!-- Header with Language and Theme Toggle -->
          <div class="flex justify-end items-center space-x-4 mb-8">
            <button
              @click="toggleLanguage"
              class="block rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white text-sm px-3 py-2"
            >
              {{ currentLanguage === 'ko' ? 'EN' : '한국어' }}
            </button>
            <ThemeToggle />
          </div>
          
          <!-- Timer Display -->
          <TimerDisplay class="mb-2" />
          <TimerControls class="mb-8" />
  
          <!-- Settings Tabs -->
          <div class="bg-gray-100 dark:bg-gray-800 rounded-xl p-5">
            <div class="flex border-b border-gray-200 dark:border-gray-700 mb-4">
              <button
                @click="activeTab = 'timer'"
                :class="[
                  'px-4 py-2 font-medium text-sm',
                  activeTab === 'timer'
                    ? 'border-b-2 border-primary dark:border-dark-primary text-primary dark:text-white'
                    : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                ]"
              >
                {{ $t('timer_settings') }}
              </button>
              <button
                @click="activeTab = 'style'"
                :class="[
                  'px-4 py-2 font-medium text-sm',
                  activeTab === 'style'
                    ? 'border-b-2 border-primary dark:border-dark-primary text-primary dark:text-white'
                    : 'text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200'
                ]"
              >
                {{ $t('style_settings') }}
              </button>
            </div>
  
            <TimerSettings v-if="activeTab === 'timer'" />
            <StyleSettings v-else />
          </div>
        </div>
      </div>
    </div>
  </template>
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useTimerStore } from '../stores/timer'
  import { useTimerIntervalStore } from '../stores/timerInterval'
  import ThemeToggle from '../components/ThemeToggle.vue'
  import TimerDisplay from '../components/TimerDisplay.vue'
  import TimerControls from '../components/TimerControls.vue'
  import TimerSettings from '../components/TimerSettings.vue'
  import StyleSettings from '../components/StyleSettings.vue'
  
  const store = useTimerStore()
  const intervalStore = useTimerIntervalStore()
  const activeTab = ref('timer')
  const { locale } = useI18n()
  const currentLanguage = ref(locale.value)
  
  const toggleLanguage = () => {
    const newLanguage = currentLanguage.value === 'ko' ? 'en' : 'ko'
    currentLanguage.value = newLanguage
    locale.value = newLanguage
    localStorage.setItem('language', newLanguage)
  }
  </script>
  
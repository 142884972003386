<!-- TimerSettings.vue -->
<template>
  <div class="space-y-6">
    <!-- Focus Time & Break Time -->
    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('focus_time_min') }}
        </label>
        <input
          v-model="focusTime"
          type="number"
          min="1"
          step="1"
          :disabled="store.isRunning"
          @input="validateAndUpdate($event, 'focusTime')"
          @keydown="preventInvalidInput"
          :title="$t('natural_number_only')"
          class="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white disabled:opacity-50"
        />
      </div>

      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('break_time_min') }}
        </label>
        <input
          v-model="breakTime"
          type="number"
          min="1"
          step="1"
          :disabled="store.isRunning"
          @input="validateAndUpdate($event, 'breakTime')"
          @keydown="preventInvalidInput"
          :title="$t('natural_number_only')"
          class="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white disabled:opacity-50"
        />
      </div>
    </div>

    <!-- Cycles & Total Time -->
    <div class="grid grid-cols-5 gap-4 items-center">
      <div class="col-span-2 space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('cycles') }}
        </label>
        <input
          v-model="cycles"
          type="number"
          min="1"
          step="1"
          :disabled="store.isRunning"
          @input="validateAndUpdate($event, 'cycles')"
          @keydown="preventInvalidInput"
          :title="$t('natural_number_only')"
          class="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white disabled:opacity-50"
        />
      </div>
      <div class="col-span-3 text-center">
        <p class="text-lg font-medium text-gray-700 dark:text-gray-300">
          {{ $t('total_time') }}: {{ formatTotalTime }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useTimerStore } from '../stores/timer'
import { useI18n } from 'vue-i18n'

const store = useTimerStore()

const { t, locale } = useI18n()
const currentLocale = ref(locale.value)

const changeLanguage = () => {
  locale.value = currentLocale.value
}

const focusTime = ref(store.focusTime)
const breakTime = ref(store.breakTime)
const cycles = ref(store.cycles)

const styleSettings = ref({
  fontFamily: store.fontFamily,
  fontSize: store.fontSize,
  fontWeight: store.fontWeight,
  textColor: store.textColor,
  backgroundColor: store.backgroundColor,
  textOpacity: store.textOpacity,
  backgroundOpacity: store.backgroundOpacity,
})

const fontSizePt = ref(parseInt(store.fontSize))

const updateFontSize = () => {
  styleSettings.value.fontSize = `${fontSizePt.value}pt`
  updateStyle()
}

const updateStyle = () => {
  store.updateStyle(styleSettings.value)
}

const updateTimerSettings = () => {
  store.updateSettings(focusTime.value, breakTime.value, cycles.value)
}

const setFocusTime = (time) => {
  focusTime.value = time
  updateTimerSettings()
}

const setBreakTime = (time) => {
  breakTime.value = time
  updateTimerSettings()
}

const formatTotalTime = computed(() => {
  const totalMinutes = (focusTime.value * cycles.value) + (breakTime.value * (cycles.value - 1))
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  
  if (hours > 0) {
    return locale.value === 'ko' 
      ? `${hours}시간 ${minutes}분`
      : `${hours}h ${minutes}m`
  }
  return locale.value === 'ko'
    ? `${minutes}분`
    : `${minutes}m`
})

const isValidNumber = (value) => {
  const num = Number(value)
  return Number.isInteger(num) && num > 0
}

const validateAndUpdate = (event, field) => {
  const value = Number(event.target.value)
  if (isValidNumber(value)) {
    if (field === 'cycles' && value <= 0) {
      cycles.value = 1 // cycles가 0 이하일 경우 1로 설정
    }
    updateTimerSettings()
  }
}

const preventInvalidInput = (event) => {
  // 소수점(.) 키 입력 방지
  if (event.key === '.') {
    event.preventDefault()
  }
  
  // 음수(-) 키 입력 방지
  if (event.key === '-' || event.key === 'e') {
    event.preventDefault()
  }
  
  // 숫자, 백스페이스, 방향키, Delete, Tab 키만 허용
  const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab']
  if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
    event.preventDefault()
  }
}

// Web 폰트 로드
onMounted(() => {
  const webFonts = [
    'https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap',
    'https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap',
    'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;600;700&display=swap',
    'https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap'
  ]

  webFonts.forEach(font => {
    const link = document.createElement('link')
    link.href = font
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  })
})
</script>
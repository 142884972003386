// src/i18n/index.js
import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    timer: 'Timer',
    settings: 'Settings',
    timer_settings: 'Timer Settings',
    style_settings: 'Style Settings',
    focus_time_min: 'Focus Time (min)',
    break_time_min: 'Break Time (min)',
    cycles: 'Cycles',
    total_time: 'Total Time',
    start: 'Start',
    pause: 'Pause',
    reset: 'Reset',
    focus: 'Focus',
    break: 'Break',
    cycle_count: 'Cycle {current}/{total}',
    natural_number_only: 'Please enter a positive integer',
    font_family: 'Font Family',
    font_weight: 'Font Weight',
    text_color: 'Text Color',
    text_style: 'Text Style',
    text_opacity: 'Text Opacity',
    background_color: 'Background Color',
    background_opacity: 'Background Opacity',
    focus_time: 'Focus Time',
    font_size: "Font Size",
    text_decoration: "Text Decoration",
    text_style_none: "None",
    text_style_italic: "Italic",
    text_style_underline: "Underline",
    text_style_strike: "Strike",
    bg_color: "Background Color",
    bg_opacity: "Background Opacity",
    reset_style: 'Reset Style'
  },
  ko: {
    timer: '타이머',
    settings: '설정',
    timer_settings: '타이머 설정',
    style_settings: '스타일 설정',
    focus_time_min: '집중 시간 (분)',
    break_time_min: '휴식 시간 (분)',
    cycles: '반복 횟수',
    total_time: '전체 시간',
    start: '시작',
    pause: '일시정지',
    reset: '리셋',
    focus: '집중',
    break: '휴식',
    cycle_count: '사이클 {current}/{total}',
    natural_number_only: '자연수만 입력 가능합니다',
    font_family: '글꼴',
    font_weight: '글꼴 굵기',
    text_color: '글자 색상',
    text_style: '글자 스타일',
    text_opacity: '글자 투명도',
    background_color: '배경 색상',
    background_opacity: '배경 투명도',
    focus_time: '집중 시간',
    font_size: "글자 크기",
    text_decoration: "텍스트 스타일",
    text_style_none: "없음",
    text_style_italic: "기울임",
    text_style_underline: "밑줄",
    text_style_strike: "취소선",
    bg_color: "배경색",
    bg_opacity: "배경 투명도",
    reset_style: '스타일 초기화'
  }
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('language') || 'ko',
  fallbackLocale: 'en',
  messages
})

export default i18n
<template>
  <Analytics />
  <router-view></router-view>
</template>

<script setup>
import { Analytics } from '@vercel/analytics/nuxt'
import { watch, onMounted, onUnmounted } from 'vue'
import { useTimerStore } from './stores/timer'
import { useTimerIntervalStore } from './stores/timerInterval'
import { useI18n } from 'vue-i18n'

const store = useTimerStore()
const intervalStore = useTimerIntervalStore()
const i18n = useI18n()

// 타이머 상태 변화 감지
watch(() => store.isRunning, (newValue) => {
  if (newValue) {
    intervalStore.startTimer()
  } else {
    intervalStore.stopTimer()
  }
}, { immediate: true })

watch(() => i18n.global.locale.value, (newLocale) => {
  document.documentElement.lang = newLocale
}, { immediate: true })

onUnmounted(() => {
  intervalStore.stopTimer()
})
</script>
// stores/timer.js
import { defineStore } from 'pinia'
import { ref } from 'vue'
import i18n from '../i18n'

export const useTimerStore = defineStore('timer', () => {
  const focusTime = ref(25)
  const breakTime = ref(5)
  const cycles = ref(4)
  const currentCycle = ref(1)
  const isRunning = ref(false)
  const isFocusTime = ref(true)
  const timeLeft = ref(focusTime.value * 60)
  const isEnded = ref(false)

  const startTimer = () => {
    console.log('Start timer called')
    if (!cycles.value || cycles.value <= 0) {
      const message = i18n.global.locale === 'ko' 
        ? '반복 횟수는 1 이상이어야 합니다.' 
        : 'Number of cycles must be greater than 0.'
      alert(message)
      return
    }

    if (isEnded.value) {
      const message = i18n.global.locale === 'ko' 
        ? '먼저 리셋 버튼을 눌러주세요.' 
        : 'Please press the reset button first.'
      alert(message)
      return
    }

    if (!isRunning.value) {
      if ('Notification' in window && Notification.permission === 'default') {
        Notification.requestPermission()
      }
      
      if (timeLeft.value === 0) {
        timeLeft.value = focusTime.value * 60
      }
      isRunning.value = true
      console.log('Timer started')
    }
  }

  const pauseTimer = () => {
    isRunning.value = false
    console.log('Timer paused')
  }

  const resetTimer = () => {
    isRunning.value = false
    isFocusTime.value = true
    currentCycle.value = 1
    timeLeft.value = focusTime.value * 60
    isEnded.value = false
    console.log('Timer reset')
  }

  const updateSettings = (newFocusTime, newBreakTime, newCycles) => {
    focusTime.value = Math.max(1, Number(newFocusTime))
    breakTime.value = Math.max(1, Number(newBreakTime))
    cycles.value = Math.max(1, Number(newCycles))
    if (!isRunning.value) {
      timeLeft.value = focusTime.value * 60
    }
    console.log('Settings updated:', focusTime.value, breakTime.value, cycles.value)
  }

  const decrementTime = () => {
    console.log('Decrement time called')
    if (!isRunning.value) return
    
    if (timeLeft.value > 0) {
      timeLeft.value--
      console.log('Time left:', timeLeft.value)
    } else {
      if (isFocusTime.value && currentCycle.value < cycles.value) {
        isFocusTime.value = false
        timeLeft.value = breakTime.value * 60
      } else if (!isFocusTime.value) {
        isFocusTime.value = true
        currentCycle.value++
        timeLeft.value = focusTime.value * 60
      } else {
        isRunning.value = false
        isEnded.value = true
      }
    }
  }

  const toggleTimer = () => {
    console.log('Toggle timer called')
    if (isRunning.value) {
      pauseTimer()
    } else {
      startTimer()
    }
  }

  return {
    focusTime,
    breakTime,
    cycles,
    currentCycle,
    isRunning,
    isFocusTime,
    timeLeft,
    isEnded,
    startTimer,
    pauseTimer,
    resetTimer,
    updateSettings,
    decrementTime,
    toggleTimer
  }
}, {
  persist: {
    paths: ['focusTime', 'breakTime', 'cycles']  // 설정값만 저장
  }
})
<template>
  <div class="flex justify-center space-x-2">
    <button
      class="btn bg-green-500 hover:bg-green-600 active:bg-green-700 dark:bg-green-600 dark:hover:bg-green-700 dark:active:bg-green-800"
      :class="{
        'bg-yellow-500 hover:bg-yellow-600 active:bg-yellow-700 dark:bg-yellow-600 dark:hover:bg-yellow-700 dark:active:bg-yellow-800': store.isRunning
      }"
      @click="store.toggleTimer"
    >
      {{ store.isRunning ? $t('pause') : $t('start') }}
    </button>
    
    <button
      class="btn bg-gray-200 hover:bg-gray-300 active:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 dark:active:bg-gray-500"
      @click="store.resetTimer"
    >
      {{ $t('reset') }}
    </button>

    <button
      class="btn bg-gray-200 hover:bg-gray-300 active:bg-gray-400 dark:bg-gray-700 dark:hover:bg-gray-600 dark:active:bg-gray-500"
      @click="handleResetStyle"
    >
      {{ $t('reset_style') }}
    </button>
  </div>
</template>

<script setup>
import { useTimerStore } from '../stores/timer'
import { useStyleStore } from '../stores/style'

const store = useTimerStore()
const styleStore = useStyleStore()

const handleResetStyle = () => {
  console.log('Reset style button clicked')
  styleStore.resetStyle()
}
</script>

<style scoped>
.btn {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  color: rgb(17 24 39);
}

.dark .btn {
  color: white;
}

.btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
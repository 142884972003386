import { defineStore } from 'pinia'

export const useStyleStore = defineStore('style', {
  state: () => ({
    fontFamily: "'Arial', sans-serif",
    fontSize: '36pt',
    fontWeight: 'normal',
    textColor: null,
    backgroundColor: '#000000',
    textOpacity: 1,
    backgroundOpacity: 0,
    textDecoration: 'none'
  }),
  actions: {
    updateStyle(newStyle) {
      Object.assign(this.$state, newStyle)
    },
    resetStyle() {
      this.fontFamily = "'Arial', sans-serif"
      this.fontSize = '36pt'
      this.fontWeight = 'normal'
      this.textColor = null
      this.backgroundColor = '#000000'
      this.textOpacity = 1
      this.backgroundOpacity = 0
      this.textDecoration = 'none'
    }
  }
})
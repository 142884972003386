import { defineStore } from 'pinia'
import { useTimerStore } from './timer'
import { ref } from 'vue'

export const useTimerIntervalStore = defineStore('timerInterval', () => {
  const intervalId = ref(null)
  const timerStore = useTimerStore()

  const startTimer = () => {
    stopTimer()
    
    intervalId.value = setInterval(() => {
      if (!timerStore.isRunning) {
        stopTimer()
        return
      }
      timerStore.decrementTime()
    }, 1000)
  }

  const stopTimer = () => {
    if (intervalId.value) {
      clearInterval(intervalId.value)
      intervalId.value = null
    }
  }

  return {
    startTimer,
    stopTimer
  }
}) 
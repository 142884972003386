<!-- StyleSettings.vue -->
<template>
  <div class="space-y-6">
    <!-- Font Family & Weight -->
    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('font_family') }}
        </label>
        <select
          v-model="fontFamily"
          @change="updateStyle"
          class="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <option value="'Arial', sans-serif">Arial</option>
          <option value="'Roboto', sans-serif">Roboto</option>
          <option value="'Open Sans', sans-serif">Open Sans</option>
          <option value="'Lato', sans-serif">Lato</option>
          <option value="'Noto Sans', sans-serif">Noto Sans</option>
        </select>
      </div>
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('font_weight') }}
        </label>
        <select
          v-model="fontWeight"
          @change="updateStyle"
          class="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <option value="300">Light</option>
          <option value="normal">Normal</option>
          <option value="500">Medium</option>
          <option value="600">Semi Bold</option>
          <option value="bold">Bold</option>
        </select>
      </div>
    </div>

    <!-- Font Size & Text Decoration -->
    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('font_size') }}
        </label>
        <input
          v-model="fontSizePt"
          type="number"
          @input="updateFontSize"
          min="10"
          max="100"
          class="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        />
      </div>
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('text_decoration') }}
        </label>
        <select
          v-model="textDecoration"
          @change="updateStyle"
          class="mt-1 block w-full px-3 py-2 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary dark:bg-gray-700 dark:border-gray-600 dark:text-white"
        >
          <option value="none">None</option>
          <option value="underline">Underline</option>
          <option value="line-through">Line-through</option>
          <option value="italic">Italic</option>
        </select>
      </div>
    </div>

    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('text_color') }}
        </label>
        <input
          v-model="textColorProxy"
          type="color"
          @input="updateStyle"
          class="mt-1 block w-full h-10 px-1 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
        />
      </div>
      <div>
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('text_opacity') }} ({{ Math.round(textOpacity * 100) }}%)
        </label>
        <input
          v-model="textOpacity"
          type="range"
          min="0"
          max="1"
          step="0.01"
          @input="updateStyle"
          class="mt-1 block w-full"
        />
      </div>
    </div>

    <!-- Background Color & Opacity -->
    <div class="grid grid-cols-2 gap-4">
      <div class="space-y-2">
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('background_color') }}
        </label>
        <input
          v-model="backgroundColorProxy"
          type="color"
          @input="updateStyle"
          class="mt-1 block w-full h-10 px-1 rounded-md border-gray-300 shadow-sm focus:border-primary focus:ring-primary"
        />
      </div>
      <div>
        <label class="block text-sm font-medium text-gray-700 dark:text-gray-300">
          {{ $t('background_opacity') }} ({{ Math.round(backgroundOpacity * 100) }}%)
        </label>
        <input
          v-model="backgroundOpacity"
          type="range"
          min="0"
          max="1"
          step="0.01"
          @input="updateStyle"
          class="mt-1 block w-full"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import { useStyleStore } from '../stores/style'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const styleStore = useStyleStore()

// 다크 모드 상태 감지
const isDarkMode = computed(() => {
  return document.documentElement.classList.contains('dark')
})

// 로컬 상태 초기화
const fontFamily = ref(styleStore.fontFamily)
const fontSizePt = ref(parseInt(styleStore.fontSize) || 36)
const fontWeight = ref(styleStore.fontWeight)
const textDecoration = ref(styleStore.textDecoration)
const textOpacity = ref(
  styleStore.textOpacity !== null && !isNaN(Number(styleStore.textOpacity))
    ? Number(styleStore.textOpacity)
    : 1
)
const backgroundOpacity = ref(
  styleStore.backgroundOpacity !== null && !isNaN(Number(styleStore.backgroundOpacity))
    ? Number(styleStore.backgroundOpacity)
    : 0
)

// 컴퓨티드 프로퍼티로 color 입력 안전하게 처리
const textColorProxy = computed({
  get: () => styleStore.textColor || (isDarkMode.value ? '#FFFFFF' : '#000000'),
  set: (val) => {
    styleStore.textColor = val
  }
})

const backgroundColorProxy = computed({
  get: () => styleStore.backgroundColor || '#000000',
  set: (val) => {
    styleStore.backgroundColor = val
  }
})

// 스타일 업데이트 함수
const updateFontSize = () => {
  const newSize = `${fontSizePt.value}pt`
  console.log('Updating font size:', newSize)
  styleStore.updateStyle({
    fontSize: newSize
  })
}

const updateStyle = () => {
  console.log('Updating style with local values:', {
    fontFamily: fontFamily.value,
    fontSize: `${fontSizePt.value}pt`,
    fontWeight: fontWeight.value,
    textColor: styleStore.textColor,
    backgroundColor: styleStore.backgroundColor,
    textOpacity: textOpacity.value,
    backgroundOpacity: backgroundOpacity.value,
    textDecoration: textDecoration.value
  })
  styleStore.updateStyle({
    fontFamily: fontFamily.value || "'Arial', sans-serif",
    fontSize: `${fontSizePt.value}pt` || '36pt',
    fontWeight: fontWeight.value || 'normal',
    textColor: styleStore.textColor || null, // null로 유지
    backgroundColor: styleStore.backgroundColor || '#000000',
    textOpacity: isNaN(Number(textOpacity.value)) ? 1 : Number(textOpacity.value),
    backgroundOpacity: isNaN(Number(backgroundOpacity.value)) ? 0 : Number(backgroundOpacity.value),
    textDecoration: textDecoration.value || 'none'
  })
}

// store 값 변경 감지 및 로컬 상태 업데이트
watch(
  () => styleStore.$state,
  (newValues) => {
    console.log('Store values changed:', newValues)
    fontFamily.value = newValues.fontFamily
    fontSizePt.value = parseInt(newValues.fontSize) || 36
    fontWeight.value = newValues.fontWeight
    textDecoration.value = newValues.textDecoration
    textOpacity.value =
      newValues.textOpacity !== null && !isNaN(Number(newValues.textOpacity))
        ? Number(newValues.textOpacity)
        : 1
    backgroundOpacity.value =
      newValues.backgroundOpacity !== null && !isNaN(Number(newValues.backgroundOpacity))
        ? Number(newValues.backgroundOpacity)
        : 0
  },
  { deep: true, immediate: true }
)
</script>